<template>
  <v-row no-gutters>

    <!-- Datatable -->
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :server-items-length="totalItems"
        :options.sync="pagination"
        :loading="loading"
        :items-per-page="20"
        style="min-height: 600px"
        sort-by="createdAt"
        :sort-desc="false"
        :footer-props="{'items-per-page-options': itemsPerPage}"
        class="elevation-1">

        <!-- Type -->
        <template v-slot:item.type="{item}">
          {{ item.type }}
        </template>

        <!-- File Name -->
        <template v-slot:item.filename="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <span
                v-bind="attrs"
                v-on="on"
            >
              {{ item.filename }}
            </span>
            </template>
            <span>{{ item.originalname }}</span>
          </v-tooltip>
        </template>

        <!-- Date -->
        <template v-slot:item.createdAt="{item}">
          {{ item.createdAt ? formatDate(item.createdAt) : $t('common.misc.NA') }}
        </template>

        <!-- Action -->
        <template v-slot:item.actions="{item}">

          <!-- Download -->
          <v-btn
              v-if="item"
              icon
              @click="download(item)"
              :loading="item.id === isLoadingDownloadFileId"
          >
            <v-icon small>fas fa-download</v-icon>
          </v-btn>

          <!-- Delete -->
          <v-btn
              icon
              @click="toggleDeleteDialog(true, item)"
              :loading="item.id === isLoadingDeleteFileId"
          >
            <v-icon small>fas fa-trash</v-icon>
          </v-btn>
        </template>

      </v-data-table>

      <!-- Delete dialog -->
      <DeleteDialog
          v-if="showDialog.delete"
          :value="showDialog.delete"
          :label="currentFile.type"
          @close="toggleDeleteDialog(false)"
          @submit="deleteFile"
      />
    </v-col>
  </v-row>
</template>

<script>
import vuetifyDataTable from "@/mixins/vuetifyDataTable";
import formatter                 from "@/mixins/formatter";

export default {
  name: "FilesDatatable",

  props: {
    URL_API: { type: Object }
  },

  components: {
    DeleteDialog: () => import("@/components/Common/DeleteDialog")

  },

  mixins: [
    vuetifyDataTable,
      formatter
  ],

  data() {
    return {
      showDialog: {
        delete: false
      },

      headers: [
        {
          text: this.$t('common.files.datatable.headers.type'),
          value: "type"
        },
        {
          text: this.$t('common.files.datatable.headers.filename'),
          value: "filename"
        },
        {
          text: this.$t('common.files.datatable.headers.createdAt'),
          value: "createdAt"
        },
        {
          text: "",
          value: "actions"
        }
      ],

      currentFile: null,
      isLoadingDownloadFileId: null,
      isLoadingDeleteFileId: null
    }
  },

  methods: {
    toggleDeleteDialog(val, currentItem = null, needRefresh = false) {
      this.currentFile = currentItem;
      if (needRefresh)
        this.searchTrigger();
      this.showDialog.delete = val;
    },
    deleteFile() {

      this.isLoadingDeleteFileId = this.currentFile.id;
      this.$http
          .delete(`${this.URL_API.DELETE}/${this.isLoadingDeleteFileId}`, {
            headers: {
              Authorization: "Bearer " + this.$session.get('jwt')
            }
          })
          .then(res => {
            this.toggleDeleteDialog(false, null, true);
          })
          .catch(err => {
            this.$store.commit("toast/showError", this.$t('common.errors.500'));
          })
          .finally(() => {
            this.isLoadingDeleteFileId = null;
          })
    },

    download(file) {

      this.isLoadingDownloadFileId = file.id;

      this.$http
          .get(`${this.URL_API.DOWNLOAD}/${this.isLoadingDownloadFileId}/download`, {
            responseType: 'arraybuffer',
            headers: {
              Authorization: "Bearer " + this.$session.get('jwt')
            }
          })
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.originalname);
            // document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch(err => {
            this.$store.commit("toast/showError", this.$t('common.errors.500'));
          })
          .finally(() => {
            this.isLoadingDownloadFileId = null;
          })
    },
  }
}
</script>

<style scoped>

</style>
